@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

p {
  text-align: justify;
}

img {
  pointer-events: none;
  user-select: none;
}

button {
  background: none;
  border: none;
}

button:hover {
  cursor: pointer
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}